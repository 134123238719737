import {hasGuestsGoing, hasImage, isInternal, isRsvp, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {isEditor, isMobile} from '../../commons/selectors/environment'
import {
  getCountdownMarginBottom as getCountdownMarginBottomCommons,
  getDateAndLocationMarginBottom,
  getDateMarginBottom,
  getDescriptionMarginBottom,
  getLocationMarginBottom,
  getRsvpMarginBottom,
  getTitleMarginBottom,
  isBackgroundLayout,
  isButtonLayout,
  isCompactDateAndLocation,
  isCompactDividersEnabled,
  isCountdownEnabled,
  isDateEnabled,
  isDescriptionEnabled,
  isFullDividersEnabled,
  isHorizontalDateAndLocation,
  isHorizontalDividersEnabled,
  isImageEnabled,
  isLabelEnabled,
  isLocationEnabled,
  isMembersEnabled,
  isOfferRibbonEnabled,
  isAnyRibbonEnabled,
  isRsvpTitleEnabled,
  isShareOptionsEnabled,
  isTitleEnabled,
  isVerticalDateAndLocation,
  isVerticalLayout,
  isAnyStatusRibbonEnabled,
} from '../../commons/selectors/settings'
import {State} from '../types/state'
import {getFirstEvent, getRibbonData} from './events'

export const getComponentConfig = (state: State) => state.component

export const isOnImageLayout = (state: State) => isBackgroundLayout(getComponentConfig(state)) && isImageVisible(state)

export const includeImageToCalculations = (state: State) => {
  const componentConfig = getComponentConfig(state)

  const verticalLayout = isVerticalLayout(componentConfig)
  const onImageLayout = isOnImageLayout(state)
  const buttonOnlyLayout = isButtonLayout(componentConfig)

  return isImageVisible(state) && (isMobile(state) ? !onImageLayout && !buttonOnlyLayout : verticalLayout)
}

export const isAllComponentsHidden = (state: State, event: wix.events.Event) =>
  !isCountdownVisible(state) &&
  !isTitleVisible(state) &&
  !isDescriptionVisible(state) &&
  !isDateVisible(state) &&
  !isLocationVisible(state) &&
  !isShareOptionsVisible(state) &&
  !isRibbonVisible(state, event)

export const isOnlyButtonVisible = (state: State) => {
  const event = getFirstEvent(state)
  return isButtonLayout(getComponentConfig(state)) || isAllComponentsHidden(state, event)
}

export const isImageVisible = (state: State) => {
  const event = getFirstEvent(state)
  return isImageEnabled(getComponentConfig(state)) && hasImage(event) && !isOnlyButtonVisible(state)
}

export const isCountdownVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isCountdownEnabled(componentConfig) &&
    !getFirstEvent(state).scheduling.config.scheduleTbd &&
    !isButtonLayout(componentConfig)
  )
}

export const isTitleVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isTitleEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isDescriptionVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return isDescriptionEnabled(componentConfig) && !!getFirstEvent(state).description && !isButtonLayout(componentConfig)
}

export const isRibbonVisible = (state: State, event: wix.events.Event) => {
  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)
  const ribbonEnabled = isAnyRibbonEnabled(componentConfig, mobile)
  return ribbonEnabled && !isWithoutRegistration(event) && !isButtonLayout(componentConfig)
}

export const isStatusRibbonVisible = (state: State, event: wix.events.Event) => {
  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)

  return isAnyStatusRibbonEnabled(componentConfig, mobile) && getRibbonData(state, event).status
}

export const isOfferRibbonVisible = (state: State, event: ExtendedEvent) =>
  isOfferRibbonEnabled(getComponentConfig(state), isMobile(state)) && event.memberOffer

export const isMembersVisibleInEditor = (state: State) => isEditor(state) && isMembersEnabled(getComponentConfig(state))

export const isMembersVisible = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)

  return (
    state.membersEnabled &&
    isInternal(event) &&
    !isButtonLayout(componentConfig) &&
    ((hasGuestsGoing(event.totalGuests, isRsvp(event)) && isMembersEnabled(componentConfig)) ||
      isMembersVisibleInEditor(state))
  )
}

export const isDateVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isDateEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isLocationVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isLocationEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isShareOptionsVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isShareOptionsEnabled(componentConfig) && !isButtonLayout(componentConfig)
}

export const isCompactDividerVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isCompactDividersEnabled(componentConfig) &&
    isDescriptionEnabled(componentConfig) &&
    isCompactDateAndLocationVisible(state)
  )
}

export const isCompactDateAndLocationVisible = (state: State) =>
  isCompactDateAndLocation(getComponentConfig(state)) && isDateOrLocationVisible(state)

export const isLabelsVisible = (state: State) => isLabelEnabled(getComponentConfig(state))

export const isDateOrLocationVisible = (state: State) => isDateVisible(state) || isLocationVisible(state)

export const isDateAndLocationVisible = (state: State) =>
  !isCompactDateAndLocation(getComponentConfig(state)) && isDateOrLocationVisible(state)

export const getCompactDateAndLocationMargin = (state: State) => {
  if (isMobile(state)) {
    return isDescriptionVisible(state) ? 40 : 30
  }

  return getDateAndLocationMarginBottom(getComponentConfig(state))
}

export const getCountdownMarginBottom = (state: State) =>
  isMobile(state) ? 42 : getCountdownMarginBottomCommons(getComponentConfig(state))

export const isVerticalDividersVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)

  return (
    isFullDividersEnabled(componentConfig) &&
    isHorizontalDateAndLocation(componentConfig) &&
    isDateVisible(state) &&
    isLocationVisible(state)
  )
}

export const isHorizontalDividersVisible = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isHorizontalDividersEnabled(componentConfig) && isVerticalDateAndLocation(componentConfig)
}

export const getDescriptionMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)

  if (isMobile(state)) {
    return isCompactDateAndLocation(componentConfig) ? 54 : 42
  }

  return getDescriptionMarginBottom(componentConfig)
}

export const getTitleMargin = (state: State) => {
  if (isMobile(state)) {
    return !isDateVisible(state) && !isLocationVisible(state) && !isDescriptionVisible(state) ? 50 : 30
  }

  return getTitleMarginBottom(getComponentConfig(state))
}

export const getDateMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)

  if (isMobile(state)) {
    return isHorizontalDateAndLocation(componentConfig) ? 0 : isLabelsVisible(state) ? 40 : 24
  }

  return isHorizontalDateAndLocation(componentConfig) ? 0 : getDateMarginBottom(componentConfig)
}

export const getRsvpButtonMargin = (state: State) =>
  isOnlyButtonVisible(state) ? 0 : getRsvpMarginBottom(getComponentConfig(state))

export const getLocationMargin = (state: State) => {
  const componentConfig = getComponentConfig(state)
  return isHorizontalDateAndLocation(componentConfig) ? 0 : getLocationMarginBottom(componentConfig)
}

export const isRsvpLabelVisible = (state: State) => isRsvpTitleEnabled(getComponentConfig(state))
